<template>
  <div class="home">
    <!-- 客服电话 -->
    <a href="tel:021-64175076" class="home_server">
      <img src="../../src/assets/imgs/home_service.png"/>
      <!-- <text class="home_server_text">客服电话</text> -->
    </a>
    <!-- 指引 -->
    <div class="home_guid" v-if="showGuidInfo">
      <div v-show="item.isShow" v-for="(item, index) in guidInfo" :key="index" :class="'home_guid_'+(index+1)">
        <img :src="item.img" alt="" @click="guidEvent(index)"/>
      </div>
    </div>
    <!-- 领取红包 -->
    <RedEnv :show="showRedEnv" @recieve="getRedEnvMoney" @closeDia="closeEvn"/>
    <Login 
      :showDialog="loginDialog" 
      :contentInfo="loginDialogInfo"
      :contentInfoIndex="loginDialogInfoIndex"
      @close="close"
      @changeTab="changeTab($event)"
      @signIn="signIn"
      @phoneLogin ="phoneLogin"
      @sendCode="sendCode"
      @signInEvent="signInEvent"
      @nextStep="nextStep"
      @forgetPasword="forgetPasword"
      @cardLogin="cardLogin"></Login>
    <!-- 顶部 -->
    <div class="home_head">
      <img src="../assets/imgs/home_head_bg.png"/>
      <ul class="head_nav">
          <li class="head_nav_item" v-for="(nav, index) in navList" :key="index" @click="goDetail(nav)">
            {{nav.label}}
          </li>
      </ul>
    </div>
    <!-- banner -->
    <div class="home_swiper">
          <swiper
            :slides-per-view="1"
            autoplay>
            <swiper-slide v-for="(item, index) in swiperInfo" :key="index">
              <div class="slider_box">
                <img @click="toNewDetail(item.articleId, item.route)" :src="item.filePath"/>
              </div>
            </swiper-slide>
            </swiper>
    </div>
    <!-- 操作按钮 -->
    <div class="home_handle_button" v-if="trueHandleButton.length > 0">
      <button
        @click="handleEvent(button.eventName, button.route)" 
        v-for="(button, index) in trueHandleButton" 
        :key="index">
        {{button.label}}
      </button>
    </div>
    <!-- 已登录状态页面 -->
    <div v-if="isLogin" class="login">
      <div class="lottery_news">
        <!-- 选择彩种 -->
        <CommonBox class="lottery_news_left bg_red">
          <ChooseLottery @lotteryEvent="lotteryEvent($event)" :canLottery="inWhiteList" tipText="该功能暂不可用" :showButton="true"/>
        </CommonBox>
        <div class="lottery_news_right">
          <common-box title="最新资讯" :showMore="true">
            <ul class="notice_list">
              <li class="notice_list_item line_omit" @click="toNewDetail(item.id)" v-for="(item, index) in noticeList" :key="index">
                {{item.title}}
              </li>
            </ul>
          </common-box>
          <common-box class="margin_top" title="电话语音投注" :showMore="true">
            <Contact/>
          </common-box>
        </div>
      </div>
      <ChooseNumbers :showButton="inWhiteList" :canLottery="inWhiteList"  :login="isLogin"/>
      <common-box class="home_content_balls margin_top" title="最新开奖">
        <div v-for="(ball, index) in openNumbers" :key="index" class="bottom_gray margin_lr_10">
          <OpenNumber :isChoose="true" :balls="ball"/>
        </div>
        <div class="arrow_gray" @click="goAllWinCode">更多</div>
      </common-box>
      <!-- 悬浮框 -->
      <div v-show="showSuspend" class="suspend_box">
        <div class="suspend_box_left">
          <span @click="closeSus" class="suspend_box_left_cross"></span>
          <img src="../assets/imgs/fc_icon_square.png"/>
          <div>
            <p>把“福彩电话投注”</p>
            <p>添加到桌面</p>
          </div>
        </div>
        <div class="suspend_box_right">
          <button @click="saveToDesk">立即添加</button>
        </div>
      </div>
    </div>
    <!-- 未登录页面 -->
    <div v-else class="not_login">
      <!-- 选择彩种 今日头条 网站公告 -->
      <div class="not_login_content">
        <div class="not_login_left">
          <common-box title="选择彩种">
            <choose-lottery :canLottery="false" @lotteryEvent="lotteryEvent(canLottery)"/>
          </common-box>
        </div>
        <div class="not_login_center">
          <common-box>
            <div class="home_toutiao" @click="toNewDetail(topNews.id)">
              <p class="common_head_red">今日头条</p>
              <h3 class="home_toutiao_title">{{topNews.fullTitle}}</h3>
              <div v-html="topNews.content && topNews.content.slice(0, 150)"></div>
            </div>
          </common-box>
          <choose-numbers :showButton="inWhiteList"/>
        </div>
        <div class="not_login_right">
          <common-box title="网站公告">
            <ul class="notice_list">
              <li class="notice_list_item line_omit" @click="toNewDetail(item.id)" v-for="(item, index) in noticeList" :key="index">
                {{item.title}}
              </li>
            </ul>
          </common-box>
          <common-box title="电话语音投注">
            <contact/>
          </common-box>
        </div>
      </div>
      <!-- 最新开奖 大奖喜讯 投注技巧 -->
      <div class="not_login_content">
         <div class="not_login_left">
           <common-box  class="home_content_balls margin_top" title="最新开奖">
              <div v-for="(ball, index) in openNumbers" :key="index" class="bottom_gray margin_lr_5">
                <OpenNumber :isChoose="true" :small="true" :showIcon="false"  :balls="ball"/>
              </div>
              <div class="arrow_gray" @click="goAllWinCode">更多</div>
           </common-box>
          </div>
          <div class="not_login_center">
            <common-box>
              <p class="common_head_red">大奖喜讯</p>
              <ul class="big_bonus">
                <li class="big_bonus_item line_omit" v-for="(item, index) in bigBonus" :key="index">
                  <p class="line_omit">【恭喜】电话投注<span>{{item[0]}}</span>喜中"双色球"<span>{{item[1]}}</span>,奖金<span>{{item[2]}}</span><span>{{item[3]}}</span></p>
                </li>
              </ul>
            </common-box>
            <div class="bonus_bg_img">
              <img src="../assets/imgs/good_news_bg.gif"/>
            </div>
          </div>
          <div class="not_login_right">
            <common-box title="中奖名单">
              <div class="win_info">
                <!-- 中奖名单标题 -->
                <ul class="win_info_title">
                  <li v-for="(item, index) in winInfoTitle" :key="index">
                    {{item}}
                  </li>
                </ul>
                <!-- 中奖名单内容 -->
                <ul class="win_info_content">
                  <li class="bottom_gray" v-for="(item, index) in winInfoText" :key="index">
                    <span>{{item[0]}}</span>
                    <span>{{item[1]}}</span>
                    <span class="red">{{item[2]}}<small>元</small></span>
                  </li>
                </ul>
              </div>
            </common-box>
          </div>
      </div>
      <!-- 中奖名单 爱心公益 留言板 -->
      <div class="not_login_content">
        <div class="not_login_left">
          <common-box>
                <div class="skill_recard">
                  <ul class="skill_recard_titles">
                    <li @click="noticeArrIndex = index" :class="{'red': index == noticeArrIndex}" v-for="(item, index) in noticeArr" :key="index">
                      {{item}}
                    </li>
                  </ul>
                  <ul class="skill_recard_content">
                    <li class="line_omit" v-for="(item, index) in skillRecard" @click="toNewDetail(item.id)" :key="index">
                      {{item.title}}
                    </li>
                  </ul>
                </div>
            </common-box>
          </div>
          <div class="not_login_center">
            <common-box>
              <p class="common_head_red">爱心公益</p>
              <div class="love_welfare">
                <img src="../assets/imgs/love_welfare_icon.png" alt="">
                <ul class="love_welfare_items">
                  <li v-for="(item, index) in loveWelfareInfo" :key="index">
                    {{item}}
                  </li>
                </ul>
              </div>
            </common-box>
          </div>
          <div class="not_login_right">
            <ul class="hot_line">
              <li class="hot_line_item" v-for="(item, index) in kfHotLine" :key="index">
                <img :src="item.bg" alt="">
                <span>{{item.label}}: </span>
                <span>{{item.text}}</span>
              </li>
            </ul>
          </div>
      </div>
    </div>
    <Footer @changeIndex="changeIndex($event)" :useProp="true" :currentIndex="currentIndex" :footerItems="tabItems"/>
  </div>
</template>

<script>
import { Dialog } from 'vant';
 // Import Swiper Vue.js components
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide} from 'swiper/vue';
import OpenNumber from '../components/OpenNumber.vue'
import ChooseLottery from '../components/home/ChooseLottery.vue'
import ChooseNumbers from '../components/home/ChooseNumber.vue'
import Contact from '../components/home/Contact.vue'
import RedEnv from '../components/RedEvn.vue'
import { getVisitor, 
         formatWinNumber, 
         inputIsAllFill, 
         getFootTab,
         jumpTo, toast, setVisitor, setMobile, getUserInfo, getStoreIdHiddenEvent, getGameName, getQueryStr, getSuspendFlag, setSuspendFlag, userOperationEvent, setIsWhiteList } from '../utils/util'

// Import Swiper styles
import 'swiper/swiper.scss';
import { reactive, toRefs } from '@vue/reactivity';
import { 
        banlance, 
        loginout, 
        swiperList, 
        newsList, 
        newWinningList, 
        login,
        loginByPhone,
        getCommonAuthorCode,
        testCommonAuthorCode,
        testRegister,
        register,
        loginByCardNum, 
        redEnvInfo,
        getEnvInfo} from '../utils/api';
import { computed, inject} from '@vue/runtime-core';
import Login from '../components/Login.vue'
import Footer from '../components/Footer.vue'
import { sleep, goTop, showGuidValue,  setGuidValue, checkPasswordFn} from '../utils/util'

SwiperCore.use([Autoplay])

export default {
     components: {
                    Swiper,
                    SwiperSlide,
                    OpenNumber,
                    ChooseLottery,
                    ChooseNumbers,
                    Contact,
                    Login,
                    RedEnv,
                    Footer
                },
      setup () {
        const state = reactive({
          headInfo: [
            '欢迎您使用！',
          ],
          showRedEnv: false,
          showSuspend: true,
          winInfoTitle: ['用户名', '彩种', '奖金'],
          winInfoText: [
            ['*****47555', '双色球', 1000],
            ['*****47555', '双色球', 1000],
            ['*****47555', '双色球', 1000],
            ['*****47555', '双色球', 1000],
            ['*****47555', '双色球', 1000],
            ['*****47555', '双色球', 1000],
          ],
          handleButton: [
            {label: '登录', eventName: 'login'},
            // {label: '查询', eventName: 'query', route: '/lotteryRecard'}
          ],
          rechargeButton: [
            {label: '充值', eventName: 'recharge', route: '/recharge'},
            {label: '查询', eventName: 'query', route: '/lotteryRecard'},
          ],
          contactInfo: [
            {img: require('../assets/imgs/home_yd_contact.png'), label: '移动'},
            {img: require('../assets/imgs/home_lt_contact.png'), label: '联通'}
          ],
          kfHotLine: [
            {label: '客服电话', text: '021-64175077/400-070-5899', bg: require('../assets/imgs/home_phone.png')},
            {label: '客服QQ', text: '2429641159', bg: require('../assets/imgs/home_qq.png')},
            {label: '邮箱', text: '2429641159@qq.com', bg: require('../assets/imgs/home_email.png')},
          ],
          navList: [
            {label: '首页'},
            {label: '电话投注使用说明', route: '/complain'},
            {label: '短信指令', route: '/message-order'},
            {label: '开奖公告', route: '/wincode'}
          ],
          // 爱心公益内容
          loveWelfareInfo: [
            '更便利，更安全，更幸福！福彩公益为铜梁敬老院温馨“护航”。',
            '总有一种力量，让爱前行！--青海福彩为民办实事，资助循环困难低保户！',
            '10亿元彩票公益金“十三五”期间救助35227名贫困病儿童。'
          ],
          openNumbers: [],
          // 网站公告
          noticeList: [],
          // 中奖新闻
          winningNews: [],
          // 投注技巧
          lotterySkills: [],
          // 中奖名单
          winningList: [],
          // 头条新闻
          topNews: {},
          isLogin: true,
          swiperInfo: [],
          bigBonus: [
            ['尾号为0120用户', '一等奖', '9,635,283', '元'],
            ['尾号为5373用户', '二等奖', '118,357', '元'],
            ['尾号为5635用户', '二等奖', '144,669', '元'],
            ['尾号为1613用户', '三等奖', '3000', '元']
          ],
          noticeArr: ['投注技巧', '中奖记录'],
          noticeArrIndex: 0,
          loginDialog: false,
          loginInfo:  [
                  {
                        title: '验证码登录',
                        inputItems: [
                                    {label: '请输入您的上海手机号', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入验证码', value: '', icon: require('../assets/imgs/clock_icon_gray.png'), eventName: 'sendCode', eventLabel: '发送验证码'},
                                ],
                        tipHandle: [
                                    {label:'忘记密码？', eventName: 'forgetPasword'},
                                    // {label: '注册', eventName: 'signIn'}
                                ],
                        submitButton: {label: '登录', eventName: 'phoneLogin'}
                    },
                    {
                        title: '卡号/手机号登录',
                        inputItems: [
                                    {label: '请输入您的卡号/上海手机号', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入您的密码', value: '', type: 'password', icon: require('../assets/imgs/clock_icon_gray.png')}
                                ],
                        tipHandle: [
                                    {label:'忘记密码？', eventName: 'forgetPasword'},
                                    // {label: '注册', eventName: 'signIn'}
                                ],
                        submitButton: {label: '登录', eventName: 'cardLogin'}
                    },
                ],
                registerInfo: [
                  {
                        title: '注册',

                        inputItems: [
                                    {label: '请输入您的身份证号', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请设置您的投注密码', type: 'password', value: '', icon: require('../assets/imgs/clock_icon_gray.png'), eventName: 'sendCode', eventLabel: '发送验证码'},
                                ],
                        submitButton: {label: '注册', eventName: 'signInEvent'}
                    },
                ],
                registerInfo1: [
                  {
                        title: '注册会员',
                        inputItems: [
                                    {label: '请输入上海手机号', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入验证码', value: '', icon: require('../assets/imgs/clock_icon_gray.png'), eventName: 'sendCode', eventLabel: '发送验证码'},
                                ],
                        submitButton: {label: '下一步', eventName: 'nextStep'}
                    },
                ],
                registerInfo2: [
                  {
                        title: '注册会员',
                        tips: '根据福彩相关规定和要求，用户需要使用有效身份信息进行实名认证。若不完成实名认证，将会无法进行投注，身份证也是领取大奖的唯一凭证。请仔细填写身份信息，完成实名认证。',
                        inputItems: [
                                    {label: '请输入您的真实姓名', value: '', icon: require('../assets/imgs/phone_icon_gray.png')},
                                    {label: '请输入您的身份证号', value: '', icon: require('../assets/imgs/clock_icon_gray.png')},
                                    {label: '请设置您的密码', value: '', type: 'password', icon: require('../assets/imgs/clock_icon_gray.png')},
                                ],
                        submitButton: {label: '注册', eventName: 'signInEvent'}
                    },
                ],
                loginDialogInfoIndex: 0,
                isRegister: false,
                canRegister: false,
                loginDialogInfo: [],
                disable: false,
                canRecharge: true,
                currentIndex: 0,
                activeInfo: {}, // 活动数据
                inWhiteList: false, // 是否在白名单
                guidInfo: [
                  {img: require('../assets/imgs/home-guid1.png'), isShow: true, eventName: 'guid1'},
                  {img: require('../assets/imgs/home-guid2.png'), isShow: false, eventName: 'guid2'}
                ],
                showGuidInfo: false,
                tabItems: getFootTab()
        })
        const loginStatus = inject('loginStatus')
        state.isLogin = loginStatus.state.isLogin
        const setLoginStatus = loginStatus.setIsLogin
        const trueHandleButtons = computed(() => {
          return state.isLogin ? state.handleButton.slice(1,2) : state.handleButton
        })
        const skillRecard = computed(() => {
          return state.noticeArrIndex == 0 ? state.lotterySkills : state.winningNews
        })
        const showRecharge = computed(() => {
          let data = !state.isLogin || state.canRecharge
          return data
        })

        const trueHandleButton = computed(() => {
          return !state.isLogin ? state.handleButton 
            : state.inWhiteList && state.canRecharge ? state.rechargeButton
            // : state.canRecharge ? state.rechargeButton
            : []
        })
        const showLogin = getQueryStr('login') == 'true'
        function initLoginStatus(login = false) {
          if (!login) {
             state.loginDialog = true
            state.loginDialogInfo = state.loginInfo
          }
        }
        initLoginStatus(!showLogin)
        function lotteryEvent(gameId) {
          if (state.isLogin) {
            if (state.inWhiteList) {
              jumpTo(`/choosenumber?gameId=${gameId}`)
            } else {
              jumpTo(`/wincodeList?gameId=${gameId}`)
            }
          } else {
            initLoginStatus(false)
          }
        }
        // 初始化信息
        function initShowInfo() {
          const value = showGuidValue()
          state.showGuidInfo =  value != 'true'
          goTop(0)
          if (!state.showGuidInfo) {
            getRedEnvInfo()
          }
        }
        // 获取红包信息
        async function getRedEnvInfo() {
          try {
            if (getVisitor() == 'true') {
              return
            }
            const result = await redEnvInfo()
            if (result.code == 0) {
              state.activeInfo = result.data[0]&&result.data[0]
              state.showRedEnv = state.activeInfo && state.activeInfo.isGive == 'NO' ? true : false
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 领取红包
        async function getRedEnvMoney() {
          try {
            const info = state.activeInfo
            const params = {
              schemeId: info.spschemeId,
              activityorderId: info.id
            }
            const result = await getEnvInfo(params)
            if (result.code == 0) {
              state.showRedEnv = false
              toast('红包领取成功，可在个人中心查看')
              state.showGuidInfo = showGuidValue() != 'true'
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 判断是否可以注册
        async function registerStatus() {
          try {
            const result = await testRegister()
            if(result.returnValue == 'true') {
              state.canRegister = true
            } else {
              state.canRegister = false
            }
          } catch(e) {
            console.error(e)
          }
        }
        registerStatus()
        // 加载开奖号码
        async function loadNumer () {
          state.openNumbers = await formatWinNumber(3)
        } 
        // 查看所有开奖号码
        function goAllWinCode() {
          jumpTo('/wincode')
        }
        // 跳转开奖号码详情
        function goWincode() {
          jumpTo('/wincode')
        }
        // 点击注册按钮事件
        function signIn() {
          if (state.canRegister) {
            state.isRegister = true
            state.loginDialogInfo = state.registerInfo1
            state.loginDialogInfoIndex = 0
          } else {
            toast('尊敬的用户，很遗憾现在不能注册')
          }
        }
        // 判断是否可以充值
        async function judegeRecharge() {
          try {
            const userInfo = await getUserInfo(true)
            if (userInfo) {
              const storeId = userInfo.storeId
              const hiddenEvent = getStoreIdHiddenEvent(storeId)
              if (hiddenEvent.indexOf('recharge') > -1) {
                state.canRecharge = false
              } else {
                state.canRecharge = true
              }
            }
          } catch(e) {
            console.error(e)
          }
        }
        judegeRecharge()
        // 注册点击下一步事件
        async function nextStep() {
          try {
            const items = state.loginDialogInfo[0].inputItems
            const params = {
              mobile: items[0].value,
              mobileCode: items[1].value
            }
            const result = await testCommonAuthorCode(params)
            if (result.code == '0000') {
              state.loginDialogInfo = state.registerInfo2
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 注册用户
        async function signInEvent() {
          try {
            const items = state.loginDialogInfo[0].inputItems
            const params = {
                mobile: state.registerInfo1[0].inputItems[0].value,
                userName: items[0].value,
                icardNumber: items[1].value,
                betPasswd: items[2].value
            }
            const result = await register(params)
            if (result.code == 0) {
                toast('注册成功, 需要重新登录')
                state.loginDialog = false
                await sleep(2000)
                state.loginDialog = true
                state.loginDialogInfo = state.loginInfo

            }
          } catch(e) {
            console.error(e)
          }
        }
        function initInfoList(arr = []) {
          let _arr = []
          arr.forEach(ele => {
            _arr.push({title: ele.fullTitle, id: ele.id})
          })
          return _arr
        }
        // 获取信息列表
        // 207网站公告 201大奖新闻 203选号技巧 800头条新闻
        async function getInfoList(gid, formatContent = true, size = 11) {
          try {
            const params = {
              gid: gid,
              size: size
            }
            const result = await newsList(params)
            if (result.code == 0) {
              return formatContent ? initInfoList(result.data) : result.data
            }
          } catch(e) {
            console.error(e)
          }
        }

        // 轮播图数据
        async function getSwiperList() {
          try {
            const result = await swiperList()
            if (result.code == 0) {
              state.swiperInfo = result.data
              state.swiperInfo.unshift({filePath: require('../assets/imgs/apply-phone/apply_phone_banner.png'), iconPath: require('../assets/imgs/apply-phone/apply_phone_banner.png'), route: '/applyPhone'})
            }
          } catch(e) {
            console.error(e)
          }
        }

        getSwiperList()

        // 信息列表
        async function infoList() {
          try {
            state.noticeList = await getInfoList(207, true, 3) || []
            if (!state.isLogin) {
              state.lotterySkills = await getInfoList(203) || []
              state.winningNews = await getInfoList(201) || []
              const topNews = await getInfoList(800, false, 1) || []
              state.topNews = topNews[0]
            }
          } catch(e) {
            console.error(e)
          }
        }
        infoList()
        // 隐藏部分
        function hiddenLitter(str) {
          const _str = str && str.replace(/^(.{2})(?:\d+)(.{4})$/,"$1*****$2")
          return _str
        }
        // 中奖人员名单
        async function winnerList() {
          try {
            const result = await newWinningList()
            if (result.code == 0) {
              state.winningList = result.data
              state.winInfoText = []
              result.data.forEach(ele => {
                // console.log(ele)
                state.winInfoText.push([hiddenLitter(ele.userName), getGameName(ele.gameId), ele.money/100])
              })
              // state.winInfoText = result.data
            }
          } catch(e) {
            console.error(e)
          }
        }
        winnerList()
        async function handleEvent(eventName, route) {
          try {
            if (!state.isLogin) {
              state.loginDialogInfo = state.loginInfo
              state.loginDialog = true
            }  else if((eventName == 'recharge') && getVisitor() == 'true'){
              let value = true
              jumpTo(`/mine?author=${value}`)
            } else if(route) {
              jumpTo(route)
            }
          } catch(e) {
            console.error(e)
          }
        }
        function goDetail(item) {
          if (item.route) {
            jumpTo(item.route)
          }
        }
        // 修改登录状态
        function changeLoginStatus(status) {
          state.loginDialog = false
          state.isLogin = status
          setLoginStatus(status)
          getUserInfo(status)
          if (status) {
            judegeRecharge()
            getWhiteListValue()
            // isInWhiteList()
          }
        }
        // // 判断是否可以在白名单
        // async function isInWhiteList() {
        //   try {
        //     const userInfo = await getUserInfo()
        //     state.inWhiteList = userInfo.open == 1
        //     inWhiteListRef.value = state.inWhiteList
        //     setIsWhiteList(state.inWhiteList)
        //   } catch(e) {
        //     console.error(e)
        //   }
        // }
        async function loadData() {
                try {
                    const result = await banlance()
                    if (result.code == 0) {
                        changeLoginStatus(true)
                    }
                } catch(e) {
                    console.error(e)
                }
        }
        loadData()
        loadNumer()
        async function getWhiteListValue() {
          try {
            const userInfo = await getUserInfo(true)
            console.log(userInfo.open)
            state.inWhiteList = userInfo.open == 1
            setIsWhiteList(userInfo.open == 1)
            state.tabItems = getFootTab()
            console.log(state.tabItems)
          } catch(e) {
            console.error(e)
          }
        }
        async function cLogin(items) {
          try {
            if (items[0].label.indexOf('卡号') > -1) {
              const params = {
                uname: items[0].value,
                password: items[1].value,
                loginType: 2,
                checkcode: 'WLGC'
              }
              const result = await loginByCardNum(params)
              if (result.code == 0) {
                toast('登录成功')
                getWhiteListValue()
                // getRedEnvInfo()
                initShowInfo()
                changeLoginStatus(true)
                setVisitor(false)
              }
            }
          } catch(e) {
            console.error(e)
          }
        }
        async function userLoginout(showToast = true) {
          try {
            const result = await loginout()
            if (result.code == 0) {
              changeLoginStatus(false)
              if(showToast){
                toast('退出登录成功')
              }
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 登录方式切换
        function changeTab(index) {
          state.loginDialogInfoIndex = index
        }
        // 关闭登陆框
        function close() {
          state.loginDialogInfoIndex = 0
          state.isRegister = false
          state.loginDialog = false
        }
        // 获取输入内容
        function getInputValue() {
          return state.loginDialogInfo[state.loginDialogInfoIndex].inputItems
        }
        // 卡号登陆
        async function cardLogin() {
          try {
            const values = getInputValue()
            // console.log(values)
            if (inputIsAllFill(values)) {
              const params = {
                uname: values[0].value,
                password: values[1].value,
                loginType: 2,
                checkcode: 'WLGC'
              }
              userOperationEvent(3, values[0].value, {})
              const result = await login(params)
              if (result.code == '0000') {
                // ---登录成功后先校验密码是否为强密码----
                await checkPassword(values[1].value, result.returnValue)
                // -----------------
                toast('登录成功')
                getWhiteListValue()
                // getRedEnvInfo()
                initShowInfo()
                changeLoginStatus(true)
              }
            } else {
              // toast('填写完整才能提交哦')
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 校验是否为强密码
        async function checkPassword(password, userInfo) {
          // eslint-disable-next-line no-async-promise-executor
          return new Promise(async (resolve) => {
            const res = await checkPasswordFn(password, false)
            if(res.code == 0){
              resolve()
            } else {
              const { userNo, mobile, phone } = userInfo
              userLoginout(false)
              await Dialog.alert({
                title:'温馨提示',
                message:'为有效防范非法登录，电话投注业务进行登录安全策略升级，请各位用户及时按照提示进行登录密码修改操作。',
              })
              jumpTo(`/changePassword?phone=${mobile || phone}&cardNo=${userNo}&p=123`)
            }
          })
        }
        // 手机号码登陆
        async function phoneLogin() {
          try {
            const values = getInputValue()
            if (inputIsAllFill(values)) {
              const params = {
                mobile: values[0].value,
                checkcode: values[1].value
              }
              userOperationEvent(3, values[0].value, {})
              const result = await loginByPhone(params) 
              if (result.code == 0  || result.code == '0000') {
                toast('登录成功')
                getWhiteListValue()
                changeLoginStatus(true)
                setVisitor(result.returnValue.type == 'TOURIST')
                initShowInfo()
                setMobile(result.returnValue.mobile)
              }
            } else {
              // toast('填写完整才能提交哦')
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 获取手机验证码
        async function sendCode() {
          if (state.disable) {
            return
          }
          try {
            const values = getInputValue()
            if(values[0].value == '') {
              toast('手机号不能为空')
              return
            } else {
              userOperationEvent(2, values[0].value, {})
              const result = await getCommonAuthorCode(values[0].value)
              if (result.code == 0) {
                // values[1].value = result.data
                state.disable = true
                let i = 60
                while(i) {
                  values[1].eventLabel = `${i}秒`
                  await sleep(1000)
                  i--
                }
                values[1].eventLabel = `获取验证码`
                state.disable = false
              }
            }
          } catch(e) {
            console.error(e)
          }
        }
        // 获取悬浮窗状态
        function getSus() {
          state.showSuspend = getSuspendFlag() == 'true'
        }
        getSus()
        // 关闭悬浮框
        function closeSus() {
          state.showSuspend = false
          setSuspendFlag(false)
        }
        // 忘记密码
        function forgetPasword() {
          jumpTo('/changePassword')
        }
        // 新闻详情
        function toNewDetail(id, route) {
          if (id) {
            jumpTo(`/newDetail?id=${id}`)
          } else {
            jumpTo(route || '/')
          }
        }
        // 保存到桌面
        function saveToDesk() {
          jumpTo('/saveDesk')
        }
        function closeEvn() {
          state.showRedEnv = false
        }
        // 页面指引
        function guidEvent(index) {
          const guid = state.guidInfo
          if (index == 0) {
            guid[0].isShow = false
            goTop(370)
            guid[1].isShow = true
          } else if(index == 1) {
            getRedEnvInfo()
            state.showGuidInfo = false
            setGuidValue(true)
            goTop(0)
          }
        }
        // // 登录后选择彩种事件
        // function loginedChooseLottery(gameId) {
        //   if (state.inWhiteList) {
        //     jumpTo(`/choosenumber?gameId=${gameId}`)
        //   } else {
        //     toast('该功能暂不可用')
        //   }
        // }
        // 切换底部tab
        function changeIndex(index) {
          state.currentIndex = index
        }
        // 获取底部tab数据
        // function getTabItems() {
        //   // setIsWhiteList(state.inWhiteList)
        //   return getFootTab()
        // }
        return {
          ...toRefs(state),
          closeEvn,
          guidEvent,
          saveToDesk,
          closeSus,
          goWincode,
          handleEvent,
          trueHandleButtons,
          userLoginout,
          jumpTo,
          goDetail,
          skillRecard,
          cLogin,
          changeTab,
          signIn,
          // loginDialogInfo,
          close,
          cardLogin,
          phoneLogin,
          sendCode,
          nextStep,
          goAllWinCode,
          signInEvent,
          forgetPasword,
          toNewDetail,
          showRecharge,
          trueHandleButton,
          lotteryEvent,
          getRedEnvMoney,
          getFootTab,
          changeIndex,
          // getTabItems
          // loginedChooseLottery
        }
      }
}
</script>

<style lang="scss" scoped>
.bg_red {
  background: #fff7f7;
}
.home_server {
  display: block;
  position: fixed;
  width: 70px;
  height: 75px;
  // background: #fff3f3;
  // border: solid $theme-dark 1px;
  right: 10px;
  top: 400px;
  z-index: 9;
  text-align: center;
  border-radius: 5px;
  img {
    // width: 100%;
    max-width: 100%;
    // padding: 3px 3px 0px 3px;
  }
  text {
    color: #000;
    font-size: $font-less;
    word-break: keep-all;
  }
}
// 悬浮框
.suspend_box {
  position: fixed;
  left: 5px;
  right: 5px;
  bottom: 65px;
  background: rgba($blue-dark, 0.85);
  border-radius: $box-radius-small;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:10px 15px;
  &_left {
    display: flex;
    align-items: center;
     img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
    &_cross {
      width: 13px;
      height: 13px;
      margin-right: 13px;
      display: inline-block;
      background: url('../assets/imgs/cross_white.png') center center no-repeat;
      background-size: contain;
    }
  }
  &_right {
    button {
      background: rgba(#fff, 0.85);
      color: $blue-dark;
      border-radius: 15px;
      padding: 2px 10px;
      font-weight: 700;
    }
  }
}
.home {
  // 引导
  &_guid {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.55);
    z-index: 9;
    &_1 {
      width: 48%;
      margin-top: 310px;
      margin-left: $margin-big;
      img {
        width: 100%;
      }
    }
    &_2 {
      padding: $margin-big;
      margin-top: 310px;
      img {
        width: 100%;
      }
    }
  }
  .get_red_env {
    display: flex;
    align-content: center;
    justify-content: center;
    img {
      width: 375px;
      height: 420px;
    }
  }
  padding-bottom: 10px;
  // 10/18 测试暂藏
  overflow-x: hidden;
  .home_content_balls {
    position: relative;
  }
  .arrow_gray {
    position: absolute;
    top: 12px;
    right: 10px;
    color: $gray-middle;
    font-size: $font-small;
  }
  .notice_list {
        padding: 3px;
        font-size: $font-less;
        height: 63px;
        &_item {
          padding: 2px 5px;
        }
  }
  .d_arrow {
    display: inline-block;
    background: url('../assets/imgs/arrow_double.png') right center no-repeat;
    background-size: 12px;
    padding-right: 15px;
  }
  &_head {
    img {
      width: 100%;
      height: auto;
    }
    .head_nav {
      display: flex;
      justify-content: space-between;
      background: #ba0000;
      margin-top: -5px;  
      color: #fff;
      font-size: $font-small;
      &_item {
        text-align: center;
        padding: 5px 10px;
        &:first-child {
          background: $theme-light;
        }
      }
    }
  }
  .home_swiper {
    overflow: hidden;
    border-radius: $box-radius-middle;
    margin: $margin-big;
    box-sizing: border-box;
  }
  &_swiper {  
    .slider_box {
      width: 100%;
      background: #efefef;  
      line-height: 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  &_handle_button {
    display: flex;
    justify-content: space-between;
    // margin-bottom: 10px;
    button {
      display: block;
      width: 100%;
      color: #fff;
      background: #3657fc;
      border: none;
      margin: 0px 10px 6px 10px;
      padding: 9px 0;
      font-size: $font-big;
      border-radius: $box-radius-small;
      &:first-child {
        background: $theme-dark;
      }
    }
  }
  .login {
    :deep {
      .contact_item{
        img {
          height: 98px;
        }
      }
      .choose_lottery_item {
        font-size: $font-big;
      }
      .choose_lottery_item .item_left small {
        font-size: $font-small;
      }
      .common_box_title {
        &::after{
          display: none;
        }
      }
      .bottom_gray {
        &::after {
          background: #ffdfdf;
        }
      }
      .choose_lottery_item {
        padding: 11px 0;
        .item_left {
          p, span {
            line-height: 1em;
          }
          p {
            padding-top: 4px;
          }
        }
      }
      .tab {
        background: #ffdddd;
        padding: 8px auto;
      }
      .choose_number .tab_item {
        padding: 8px 0;
        color: #662525;
      }
      .choose_number, .choose_number .tab_active {
        background: #fff7f7;
        color: $theme-dark;
      }
      .choose_lottery {
        background: #fff7f7;
      }
    }
    margin: 0 $margin-big;
    margin-top: -10px;
    .lottery_news {
      display: flex;
      justify-content: space-between;
      margin: 5px 0 $margin-big 0;
      &_right {
        width: 47%;
      }
    }
    // 联系方式
    .contact_info {
      padding-top: 10px;
      display: flex;
      flex-wrap: wrap;
      word-break: keep-all;
      &_item {
        display: flex;
        align-items: center;
        font-size: $font-less;
        margin-right: 13px;
        padding-bottom: 5px;
        img {
          max-height: 15px;
          max-width: 13px;
          padding-right: 7px;
        }
      }
    }
  }
  .not_login {
    width: 940px;
    // 10/18 测试暂藏
    transform: scale(0.435);
    margin-left: -264px;
    margin-top: -240px;
    margin-bottom: -200px;
    .common_head_red {
          color: $theme-dark;
          font-weight: 700;
          border-bottom: solid $theme-light 3px;
          display: inline-block;
          padding: 5px 10px;
          z-index: 1;
          &::after{
            position: absolute;
            content: '';
            height: 1px;
            background: $theme-dark;
            left: 0;
            right: 0;
            top: 43px;
            background: $line-gray;
            z-index: 0
          }
    }
    &_content {
      display: flex;
      margin: 0 $margin-big;
    }
    &_left {
      width: 190px;
      
       // 投注技巧&中奖记录
      .skill_recard {
        // font-size: $font-less;
        height: 148px;
        overflow: hidden;
        &_titles {
          display: flex;
          font-weight: 700;
          padding: 5px 0 5px 9px;
          border-bottom: solid $line-gray 1px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            width: 3px;
            height: 15px;
            border-radius: $box-radius-small;
            left: 3px;
            top: 8px;
            background: $theme-dark;
          }
          &>li {
            margin-right: 5px;
          }
        }
        &_content {
          padding: 3px;
          line-height: 1.8em;
          font-size: $font-less;
        }
      }
    }
    &_center {
      width: 420px;
      margin: 0 10px;
      font-size: $font-middle !important;
      position: relative;
      // 大奖喜讯动图
      .bonus_bg_img {
        border-radius: $box-radius-small;
        overflow: hidden;
        width: 100%;
        height: 70px;
        margin-top: 10px;
        img {
          max-width: 100%;
        }
      }
      // 头条
      .home_toutiao {
        padding: 0 0 $margin-big 0;
        margin-bottom: $margin-big;
        height: 217px;
        overflow: hidden;
        &>h3 {
            text-align: center;
            padding: 18px 0 10px 0;
            font-size: $font-big;
          }
        &>div {
          padding: 0 $margin-big 20px $margin-big;
        }
      }
      // 大奖喜讯
      .big_bonus {
        padding: $margin-big*1.3 $margin-big;
        font-size: $font-less;
        font-weight: 700;
        height: 105px;
        overflow: hidden;
        // line-height: 2em;
        li {
          p {
            line-height: 2.2em;
          }
        }
        span {
          color: $theme-light;
          &:last-child{
            color: #000;
          }
        }
      }
      // 爱心公益
      .love_welfare {
        display: flex;
        img {
          height: 95px;
          margin-right: 10px;
        }
        font-size: $font-less;
        padding: $margin-big;
        height: 96px;
        li {
          line-height: 1.4em;
        }
      }
    }
    &_right {
      width: 190px;
      .win_info {
        padding: 6px 0;
        font-size: $font-small;
        &_title {
          display: flex;
          padding-bottom: 5px;
          border-bottom: solid $line-gray 1px;
          margin-bottom: 5px;
          li {
            width: 33%;
            text-align: center;
            border-right: solid $line-gray 1px;
            &:last-child{
              border-left: none;
            }
          }
        }
        &_content {
          font-size: $font-less;
          height: 170px;
          overflow: hidden;
          li {
            display: flex;
            justify-content: space-between;
            // border-bottom: solid $line-gray 1px;
            padding: 6px 0;
            align-items: center;
            &:last-child{
              border-bottom: none;
              padding-bottom: 7px;
              border-bottom: none;
            }
            span {
              display: inline-block;
              width: 33%;
              text-align: center;
              small {
                color: #000;
              }
            }
          }
        }
      }
      .notice_list {
        padding: 3px;
        font-size: $font-less;
        height: 63px;
        &_item {
          padding: 3px 0;
        }
      }
     
      // 客服热线
      .hot_line {
        background: url('../assets/imgs/kf_list_bg.png') center center no-repeat;
        background-size: cover;
        padding: $margin-big*3 $margin-big;
        border-radius: $box-radius-small;
        margin-top: 10px;
        overflow: hidden;
        &_item {
          font-size: $font-less;
          padding: 7px 0;
          img {
            height: 10px;
          }
        }
      }
    }
  }
}
</style>